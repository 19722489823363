// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialCart = () => {
  const item = window.localStorage.getItem('cart')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: initialCart()
  },
  reducers: {
    handleCart: (state, action) => {
      state.cart = action.payload
      localStorage.setItem('cart', JSON.stringify(action.payload))
    },
    deleteCart: state => {
      state.cart = {}
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('cart')
    }
  }
})

export const { handleCart, deleteCart } = cartSlice.actions

export default cartSlice.reducer
