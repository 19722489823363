import axios from 'axios'
import apiDefaultConfig from '../apiDefaultConfig'

function setToken(token: any, usuario: any) {
  if (!token) {
    localStorage.setItem("sessionToken", token)
    localStorage.setItem("sessionTokenexpiration", token)
    localStorage.setItem("sessionAnonymous", token)
  } else {
    localStorage.setItem("sessionToken", token.accessToken)
    localStorage.setItem("sessionTokenexpiration", token.expiration)
    localStorage.setItem("sessionAnonymous", usuario.anonymous)
  }
}

export function Login(user: any) {
  axios
    .post(apiDefaultConfig.baseURL + '/Autenticacao/v1/Auth', user)
    .then(res => {
      const { data } = res
      setToken(data.object.token, data.object.usuario)
    })
}

export function getToken() {
  if (localStorage.getItem("sessionToken") != "undefined" && localStorage.getItem("sessionToken") != null) {
    return localStorage.getItem("sessionToken")
  } else {
    return null
  }
}

export function GetAnonymousToken() {
  if (getToken()) {
    if (new Date(localStorage.sessionTokenexpiration) < new Date()) {
      Login({ "usuario": Date.now(), "senha": Date.now(), "Anonymous": true, "token": "6e4a0edd4efb0f8e87d86e9b1a0419f1" })
    }
  } else {
    Login({ "usuario": Date.now(), "senha": Date.now(), "Anonymous": true, "token": "6e4a0edd4efb0f8e87d86e9b1a0419f1" })
  }
}