export enum Sites {
  NovoAssist,
  Treviso,
}

const useSite = () => {
  if (window.location.href.includes('treviso')) {
    return Sites.Treviso
  } else if (window.location.href.includes('novoassist')) {
    return Sites.NovoAssist
  } else {
    return Sites.Treviso
  }
}

export const useRootClass = () => {
  const site = useSite()
  if (site === Sites.NovoAssist) {
    return 'na'
  } else if (site === Sites.Treviso) {
    return 'tr'
  }
}

export default useSite