import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    message: ''
  },
  reducers: {
    loading: (state, action) => {
      state.loading = true
      state.message = action.payload
    },
    stopLoading: (state) => {
      state.loading = false
      state.message = ''
    }
  },
})

// Action creators are generated for each case reducer function
export const { loading, stopLoading } = appSlice.actions

export default appSlice.reducer