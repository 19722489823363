// ** Logo
import logo from '@src/assets/images/logo/favicon.ico'
import logoTreviso from '@src/assets/images/logo/logo-treviso.png'

import useSite, { Sites } from '../../../utility/Sites'

const SpinnerComponent = () => {

  const site = useSite()

  return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={site === Sites.NovoAssist ? logo : logoTreviso} alt='logo'/>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export const LoadingComponent = () => {
  return (
    <div className='fallback-spinner' style={{
      marginTop: '40px',
      marginBottom: '40px'
    }}>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export const SmallLoadingComponent = () => {
  return (
    <div className='fallback-spinner small'>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
